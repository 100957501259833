import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "hooks/useTranslation";
import {
  EXISTING_ENHANCEMENTS_KEYS,
  EXISTING_KEYS,
  FEATURES_INFOS,
  PRODUCT_SLIDES,
  PRODUCTS_WITH_SLIDER_KEYS,
  STANDARD_FEATURES_BY_KEY,
} from "./constants";
import { FeatureItem } from "./components/FeatureItem";
import { InformativeBlock } from "shared/components/InformativeBlock";
import { HorizontalProductSwipe } from "shared/components/HorizontalProductSwipe";
import { ProductItem } from "shared/components/ProductItem";
import { SectionTitleLeftWaves } from "shared/components/SectionTitleLeftWaves";
import { TransparentDialog } from "shared/components/TransparentDialog";
import styles from "./Product.module.scss";
import { IconThreeWavesToRight } from "icons";
import { useSelector } from "react-redux";
import { productsListSelector } from "reduxStore/slices/selectors";
import { OPTIONAL_FEATURES_BY_KEY } from "./constants";
import { ResponsiveImage } from "shared/components/ResponsiveImage";
import { shuffleArray } from "utils/arrayUtils";

export const Product = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const name = queryParams.get("name");
  const chosenItemName =
    queryParams.get("option") && queryParams.get("option").replaceAll("_", " ");

  const productsList = useSelector(productsListSelector);

  const filteredProducts = productsList.filter(
    product => product.name.toLowerCase() !== name?.toLowerCase()
  );

  const randomProducts = shuffleArray(filteredProducts).slice(0, 6);

  const currentProduct = productsList.find(prod => {
    return prod.device_name?.toLowerCase() === name?.toLowerCase();
  });

  const [defaultActiveSwiperIdx, setDefaultActiveSwiperIdx] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const horizontalSwiperRef = useRef(null);

  const { translate } = useTranslation();

  const contentKey = EXISTING_KEYS[name] || "default";
  const enhancementsContentKey = EXISTING_ENHANCEMENTS_KEYS[name] || "default";
  const productSliderKey = PRODUCTS_WITH_SLIDER_KEYS[name] || "default";

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (name && chosenItemName) {
      horizontalSwiperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      const selectingSwiperItemIdx = PRODUCT_SLIDES[
        enhancementsContentKey
      ].findIndex(
        item => item?.name?.toLowerCase() === chosenItemName?.toLowerCase()
      );
      setDefaultActiveSwiperIdx(selectingSwiperItemIdx);
      navigate(location.pathname + location.search, {
        replace: true,
        state: {},
      });
    }
  }, [name, chosenItemName]);

  return (
    <div className={styles.container}>
      <div className={styles.featuresBlock}>
        <div className={styles.features}>
          <h1 className={styles.title}>
            {translate("Standard Features")}
            <IconThreeWavesToRight className={styles.titleIcon} />
          </h1>
          {currentProduct?.standard_features?.map((feat, idx) => {
            const currentFeature = STANDARD_FEATURES_BY_KEY[feat.key];
            if (!feat.available) {
              return null;
            }
            return <FeatureItem {...currentFeature} enabled key={idx} />;
          })}
        </div>
      </div>
      <div className={styles.infos}>
        {FEATURES_INFOS[contentKey].map((info, idx) => (
          <InformativeBlock key={idx} text={info} />
        ))}
      </div>
      {currentProduct && (
        <div className={styles.systemOptions}>
          <div className={styles.prodImgContainer} onClick={openModal}>
            <ResponsiveImage
              className={styles.productImg}
              src={currentProduct.options_image || currentProduct.img}
              alt="Product"
            />
          </div>
          {[
            ...currentProduct.optional_features,
            ...currentProduct.other_options,
          ].map((opt, idx) => {
            const currentOpt = OPTIONAL_FEATURES_BY_KEY[opt?.key];
            if (!currentOpt) {
              return null;
            }
            const forwardTo = opt.link;

            return (
              <FeatureItem
                {...currentOpt}
                pointColor={opt.pointColor}
                enabled={opt.available} // changed from opt.state && opt.available
                key={idx}
                forwardTo={forwardTo}
                text={currentOpt.name}
              />
            );
          })}
        </div>
      )}
      <div ref={horizontalSwiperRef} className={styles.productSlideSec}>
        <HorizontalProductSwipe
          defaultActiveSwiperIdx={defaultActiveSwiperIdx}
          slides={PRODUCT_SLIDES[productSliderKey]}
        />
      </div>
      <div className={styles.section}>
        <SectionTitleLeftWaves title={translate("What Others Chose")} />
        <div className={styles.otherChoiceContent}>
          {randomProducts.map((item, idx) => (
            <ProductItem
              key={idx}
              name={item.device_name}
              description={item.details}
              content={item.image}
              forwardTo={`/product?name=${item.device_name}`}
            />
          ))}
        </div>
      </div>

      {currentProduct && (
        <TransparentDialog isOpen={isModalOpen} onClose={closeModal}>
          <img
            className={styles.productImgInModal}
            src={currentProduct.options_image || currentProduct.img}
            alt="Product"
          />
        </TransparentDialog>
      )}
    </div>
  );
};
