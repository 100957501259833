/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

export const useOutsideClick = (ref, handler, id, excludeRefs) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      const popupOpeningComponent = document.getElementById(id);
      if (
        popupOpeningComponent &&
        popupOpeningComponent.contains(event.target)
      ) {
        return;
      }

      const isExcluded = excludeRefs?.some(excludeRef => {
        if (typeof excludeRef === "string") {
          const excludedElement = document.getElementById(excludeRef);
          return excludedElement && excludedElement.contains(event.target);
        } else if (excludeRef.current) {
          return excludeRef.current.contains(event.target);
        }
        return false;
      });

      if (!isExcluded) {
        handler(event);
      }
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler, id]);
};
