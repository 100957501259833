import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "services/api";

const initialState = { companiesList: [], selectedCompany: null };

export const fetchCompaniesListAsync = createAsyncThunk(
  "company/fetchCompaniesList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get("/company/list");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCompaniesListAsync.fulfilled, (state, action) => {
        state.companiesList = action.payload;
      })
      .addCase(fetchCompaniesListAsync.rejected, (state, action) => {
        console.error(action.payload);
      });
  },
});

export const { setSelectedCompany } = companySlice.actions;

export default companySlice.reducer;
