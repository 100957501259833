// Root.js
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import ReactModal from "react-modal";
import { useStore } from "reduxStore/store";
import App from "./App";

ReactModal.setAppElement("#root");
const snackOpts = { vertical: "top", horizontal: "right" };

const Root = () => {
  const { store, persistor } = useStore();

  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={snackOpts}
        autoHideDuration={3000}
      >
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </SnackbarProvider>
    </Provider>
  );
};

export default Root;
