import Dialog from "@mui/material/Dialog";
import styles from "./PrimaryDialog.module.scss";

const backdropColorsByMode = {
  light: "#000000D4",
  darkBlur:
    "linear-gradient( 180deg, rgba(50, 50, 50, 0.83) 0%, rgba(0, 0, 0, 0.83) 100%)",
};

export const PrimaryDialog = ({
  children,
  size = "md",
  open,
  onClose,
  classes,
  className,
  dialogRef,
  mode = "light",
  radius = "radius-default",
  blurBackdrop,
  ...other
}) => {
  return (
    <Dialog
      ref={dialogRef}
      open={open}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: `${className} ${styles.dialogPaper} ${styles[size]} ${styles[mode]} ${styles[radius]}`,
        },
      }}
      maxWidth={size}
      fullWidth={true}
      BackdropProps={{
        style: {
          background: backdropColorsByMode[mode] || "rgba(0, 0, 0, 0.5)",
          backdropFilter: blurBackdrop ? "blur(8px)" : "none",
          WebkitBackdropFilter: blurBackdrop ? "blur(8px)" : "none",
        },
      }}
      {...other}
    >
      {children}
    </Dialog>
  );
};
