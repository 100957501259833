import React, { useState, useEffect } from "react";

export const ResponsiveImage = props => {
  const { src, alt } = props;
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setDimensions({ width: img.width, height: img.height });
    };
  }, [src]);

  const isWide = dimensions.width > dimensions.height;

  return (
    <img
      {...props}
      src={src}
      alt={alt}
      style={{
        width: isWide ? "78%" : "auto",
        height: isWide ? "auto" : "78%",
      }}
    />
  );
};
