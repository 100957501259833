import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "hooks/useTranslation";
import styles from "./FileInput.module.scss";
import { IconRefresh } from "icons";

export const FileInput = ({
  onFileUpload,
  placeholder,
  size = "md",
  imgUrl,
}) => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(imgUrl);

  const { translate } = useTranslation();

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(fileUrl);
      if (onFileUpload) {
        onFileUpload(selectedFile);
      }
    }
  };

  const handleAddClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <div className={`${styles.fileInput} ${styles[size]}`}>
      {previewUrl && (
        <img src={previewUrl} alt="Preview" className={styles.imagePreview} />
      )}
      {!previewUrl && <span className={styles.inputLabel}>{placeholder}</span>}
      <input
        id="fileInput"
        type="file"
        className={styles.hiddenInput}
        onChange={handleFileChange}
        accept="image/png, image/jpg, image/jpeg"
      />
      <span className={styles.addOrChangeButton} onClick={handleAddClick}>
        {previewUrl ? <IconRefresh /> : <AddIcon />}{" "}
        {translate(previewUrl ? "Change" : "Add")}
      </span>
    </div>
  );
};
