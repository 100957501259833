import { useState } from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MUISelect from "@mui/material/Select";
import { LANGUAGES } from "constants";
import { IconArrowDownSquare } from "icons";

const StyledFormControl = styled(FormControl)({
  // ... styles for the form control
});

const StyledSelect = styled(MUISelect)({
  // ... styles for the select input
});

const StyledMenuItem = styled(MenuItem)({
  // ... styles for the menu items
});

const DropdownIcon = styled(IconArrowDownSquare)({
  top: "calc(50% - 15px) !important",
  height: "22px",
  width: "auto",
});

export const LanguageSelect = ({
  label,
  value,
  onChange,
  items = LANGUAGES,
  helperText,
}) => {
  const [country, setCountry] = useState("en");
  const [open, setOpen] = useState(false);

  const handleChange = event => {
    const newCountry = event.target.value;
    setCountry(newCountry);
    if (onChange) {
      onChange(event); // Trigger the passed onChange event with the new value
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <StyledFormControl>
        {label && <InputLabel htmlFor="open-select">{label}</InputLabel>}
        <StyledSelect
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={country}
          name="country"
          onChange={handleChange}
          IconComponent={DropdownIcon}
          inputProps={{
            id: "open-select",
          }}
        >
          {items.map((option, key) => (
            <StyledMenuItem value={option.code} key={key}>
              {option.flag}
            </StyledMenuItem>
          ))}
        </StyledSelect>
        {helperText && <p>{helperText}</p>}
      </StyledFormControl>
    </div>
  );
};
