import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "hooks/useTranslation";
import { MainSwiper } from "shared/components/MainSwiper";
import { DynamicLink } from "shared/components/DynamicLink";
import { BrandCreationDialog } from "shared/components/BrandCreationDialog";
import { useDialog } from "hooks/useDialog";
import styles from "./Home.module.scss";
import RedLogo from "assets/logos/red-logo.png";
import ImageProcessor from "assets/images/home/image-processor.png";
import { useDispatch, useSelector } from "react-redux";
import { companiesListSelector } from "reduxStore/slices/selectors";
import { selectedCompanySelector } from "reduxStore/slices/selectors";
import { setSelectedCompany } from "reduxStore/slices/companySlice";

const HOME_SLIDES = [
  {
    image: ImageProcessor,
  },
];

export const Home = () => {
  const { company } = useParams();

  const brandCreationInitialForm = {
    logo: null,
    name: "",
    intro: "",
    launch_page: "",
  };
  const dispatch = useDispatch();
  const savedClients = useSelector(companiesListSelector);
  const selectedBrand = useSelector(selectedCompanySelector);
  const [brandCreationForm, setBrandCreationForm] = useState(
    brandCreationInitialForm
  );

  const { translate } = useTranslation();
  const brandCreationDialog = useDialog();
  const dialogRef = useRef(null);
  const selectRef = useRef(null);

  const handleClose = () => {
    brandCreationDialog.closeDialog();
  };

  useEffect(() => {
    if (savedClients?.length) {
      const selectedBrand = savedClients.find(
        client => client.path === company
      );
      dispatch(setSelectedCompany(selectedBrand));
    }
  }, [selectedBrand, savedClients]);

  return (
    <>
      <BrandCreationDialog
        form={brandCreationForm}
        setForm={setBrandCreationForm}
        handleClose={handleClose}
        selectRef={selectRef}
        dialogRef={dialogRef}
        isOpen={brandCreationDialog.isOpen}
        brandCreationDialog={brandCreationDialog}
        savedClients={savedClients}
      />
      <div className={styles.container}>
        <div className={styles.leftSec}>
          <div className={styles.header}>
            <img
              onClick={() => brandCreationDialog.openDialog()}
              className={styles.logo}
              src={RedLogo}
              alt="Pillarhouse"
            />
            <div className={styles.verticalLine}></div>
            {selectedBrand?.logo_url && (
              <h4 className={styles.companyName}>
                <div className={styles.companyLogoContainer}>
                  <img src={selectedBrand.logo_url} alt={selectedBrand.name} />
                </div>
              </h4>
            )}
          </div>
          <div className={styles.content}>
            <h2 className={styles.title}>
              <p className={styles.welcome}>{translate("Welcome")}</p>
              <p className={styles.name}>{selectedBrand?.name}</p>
            </h2>
            <p className={styles.description}>
              {translate(
                "Explore our innovative range of fluxing products designed to elevate your soldering experience. Trust in our commitment to quality and performance as we pave the way for seamless connections."
              )}
            </p>
          </div>
          <div className={styles.letStartBlock}>
            <p className={styles.letsStartTxt}>
              {translate(
                "Let's embark on a journey of superior soldering together!"
              )}
            </p>
            <DynamicLink
              to={
                selectedBrand?.launch_page &&
                selectedBrand.launch_page !== "home"
                  ? `/product?name=${selectedBrand.launch_page}`
                  : "/product"
              }
              className={styles.startBtn}
            >
              {translate("Get Started")}
            </DynamicLink>
          </div>
        </div>
        <MainSwiper
          direction={"vertical"}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className={styles.homeSlider}
        >
          {HOME_SLIDES.map((slide, idx) => (
            <SwiperSlide key={idx}>
              <div className={styles.slideContent}>
                <img className={styles.img} src={slide.image} alt={idx} />
              </div>
            </SwiperSlide>
          ))}
        </MainSwiper>
      </div>
    </>
  );
};
