export const getError = err => {
  if (err?.response?.data?.data && Array.isArray(err.response.data.data)) {
    return err.response.data.data.map(error => `- ${error}`).join("\n");
  }

  if (err?.response?.data?.message) {
    return err.response.data.message;
  }
  if (err?.response?.statusText) {
    return err.response.statusText;
  }
  if (err?.data?.message) {
    return err.data.message;
  }
  if (err?.message) {
    return err.message;
  }

  return String(err);
};
