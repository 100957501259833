import styles from "./CompanyCard.module.scss";

export const CompanyCard = props => {
  const { name, logo, onSelect } = props;
  return (
    <div onClick={onSelect} className={styles.container}>
      <div className={styles.logoBlock}>
        {logo ? (
          <img className={styles.logo} src={logo} alt={name} />
        ) : (
          <p className={styles.txt}>Logo</p>
        )}
      </div>
      <p className={styles.name}>{name}</p>
    </div>
  );
};
