// Add the base URL from the environment
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

export const CHARACTERISTICS = {
  default: [
    {
      section: "",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/unmarked-car-mechanics.png`,
    },
    {
      section: "Main Construction Unit",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/power-construction-unit.png`,
    },
    {
      section: "Various PCB",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/various-PCB.png`,
    },
    {
      section: "Dashboard",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/dashboard.png`,
    },
    {
      section: "Tire Pressure Monitor",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/tire-pressure-monitor.png`,
    },
    {
      section: "Switches, sensors, motors",
      title: "Built for Safety",
      paragraphs: [
        "Battery Management Systems: Ensure optimal performance and longevity with our precise soldering for BMS components.",
        "Power Electronics: Trust us for reliable soldering solutions for power electronic systems in EVs.",
        "Motor Controllers: Maximize efficiency and performance with our expert soldering for motor control units.",
        "Charging Systems: From connectors to circuit boards, we handle all aspects of soldering for EV charging systems.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/switches-sensors-motors.png`,
    },
  ],
  "automotive/ev": [
    {
      section: "",
      title: "Automotive/EV  PCB and Power Electronics",
      paragraphs: [
        "Revolutionize your EV and automotive manufacturing with Pillarhouse's advanced selective soldering systems. Engineered for high throughput and precision, our comprehensive lineup delivers unmatched performance.",
        "From integrated fully closed-loop IR technology to the high speed full convection , our automtive specialized systems are high weight carrying and thermal mass accommodating.",
        "Discover the unparalleled efficiency of our unique Jetwave and 1.5 micro thermal nozzle designs for exceptional high-density, high thermal mass production. Elevate your manufacturing prowess with Pillarhouse today.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/unmarked-car-mechanics.png`,
    },
    {
      section: "Power Conversion Unit",
      title: "Main Construction Unit",
      paragraphs: [
        "40kg capable roller conveyors, combination high watt density closed loop IR and full convection heat, pcb/module size of up to 24” x 48”, special high flow nozzles for chokes, thransformers and high thermal mass devices",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/power-construction-unit.png`,
    },
    {
      section: "Various PCB",
      title: "ECU",
      paragraphs: [
        " High speed modular or intergated approach for highest throughput efficiency, warpage correction for panelized/pre-routed/scored panels, jetwave specifically for high pin count ecu connectors",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/various-PCB.png`,
    },
    {
      section: "Dashboard",
      title: "Standard JJ! size",
      paragraphs: [
        "Special nozzle design for headers,steppers, lcd connections, additive modules for volume growth without retooling.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/dashboard.png`,
    },
    {
      section: "Tire Pressure Monitor",
      title: "TPMS",
      paragraphs: [
        "Bottom side heat to heat imbedded pcbs, flux volume calculation or measurement to avoid TPMS topside unactivated flux*, warpage correction, multi head soldering for speed and control.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/tire-pressure-monitor.png`,
    },
    {
      section: "Switches, sensors, motors",
      title: "Window Switch/Lock Switch/Mirror control",
      paragraphs: [
        "Revolutionize your EV and automotive manufacturing with Pillarhouse's advanced selective soldering systems. Engineered for high throughput and precision, our comprehensive lineup delivers unmatched performance.",
        "From integrated fully closed-loop IR technology to the high speed full convection , our automtive specialized systems are high weight carrying and thermal mass accommodating.",
        "Discover the unparalleled efficiency of our unique Jetwave and 1.5 micro thermal nozzle designs for exceptional high-density, high thermal mass production. Elevate your manufacturing prowess with Pillarhouse today.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/switches-sensors-motors.png`,
    },
  ],
  medical: [
    {
      section: "XXX",
      title: "RF Coil and Gradient Coil PCbs",
      paragraphs: [
        "Specialized closed-loop preheat for high thermal mass, smallest 1.2mm micro thermal nozzle for tight component spacing, high clearance to accommodate tall components, dual heating options with IR and convection heat for sensitive components, designed specifically for soldering difficult RF coil PCBs, fine pump control and high output nozzles for handling Electroless Nickel Immersion Gold",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/medical_banner_2.jpg`,
    },
    {
      section: "XXX",
      title: "Implantable Medical devices",
      paragraphs: [
        "Our selective soldering machine offers features tailored to the needs of implantable medical devices, including precise flux measurement in micrograms per cycle, MES system tracking and exporting critical metrics to a database, a specialized focus heat system to activate flux without overheating sensitive components, and micro nozzle technology for the tightest spaces in the industry. Additionally, our active warpage correction technology ensures that critical components re protected by avoiding contact and adjusting for any PCB warpage, maintaining the highest standards of precision and reliability.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/medical_characteristics_2.png`,
    },
    {
      section: "XXX",
      title: "LED light pcbs and specialized lighting panels",
      paragraphs: [
        "LED panels often require high reach and extended nozzle technology. Pillarhouse has fully incorporated nitrogen shrouds for ideal inertion even at extended ranges, with N2 monitoring of both flow and purity. The Shark Fin Jet Tube is designed to reach within and between LEDs for high-volume soldering. High throughput modular stackable designs allow us to achieve the necessary throughput with single-point ease of programming.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/medical_characteristics_3.jpg`,
    },
    {
      section: "XXX",
      title: "Medical Devices",
      paragraphs: [
        "Unlock unparalleled precision and efficiency with our advanced selective soldering machine, meticulously designed to meet the exacting standards of implantable medical devices. Our system measures the exact amount of flux sprayed on each device in micrograms per cycle, ensuring impeccable accuracy. Integrated with an MES system, it tracks and exports critical metrics to a database, providing comprehensive data insights for quality control. The specialized focus heat system activates the flux without overheating sensitive components, safeguarding the integrity of delicate medical devices.",
        "For LED panels requiring high reach and extended nozzle technology, Pillarhouse has incorporated nitrogen shrouds for ideal inertion, even at extended ranges. Our advanced N2 monitoring system ensures both flow and purity, maintaining optimal conditions for precise soldering. The innovative Shark Fin Jet Tube is expertly designed to reach within and between LEDs, achieving high-volume soldering with exceptional accuracy. This technology ensures that every solder joint meets the highest quality standards, even in the most challenging environments.",
        "Our modular stackable designs offer high throughput and flexibility, allowing you to build the necessary throughput with ease. The single-point programming feature simplifies operations, making it effortless to achieve the desired output. Whether you are working with complex medical devices or intricate LED panels, our selective soldering machine provides the reliability and precision you need to excel in your industry. Experience the next level of soldering technology with our cutting-edge solutions.",
      ],
      type: "image",
      content: `${IMAGE_BASE_URL}market-sector/medical_characteristics_4.png`,
    },
  ],
};
