import { useState } from "react";
import { useSelector } from "react-redux";
import { productsListSelector } from "reduxStore/slices/selectors";
import { useTranslation } from "hooks/useTranslation";
import { MainPopover } from "shared/components/MainPopover";
import { CellItem } from "../CellItem";
import styles from "./ProductItem.module.scss";
import { IconSettings, IconSettingsLight, IconDeleteRecycle } from "icons";

export const ProductItem = ({
  index,
  handleClickDelete,
  popoverRef,
  handleAddProduct,
  handleOpenSelectCell,
  handleSelectLineItem,
  productItem,
  selectedLineItem,
}) => {
  const { translate } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const list = useSelector(productsListSelector);
  // const productsList = list.filter(item => item.add_to_configurator);
  const productsList = list;
  const items = [
    { name: "Pilot" },
    { name: "Jade MKII" },
    { name: "Jade Pro Layouts" },
    { name: "Jade Prodex Layouts" },
    { name: "Fusion 3 Compact" },
    { name: "Fusion 3 Standard" },
    { name: "Fusion 4" },
    { name: "Synchrodex Fluxer Module" },
    { name: "Synchrodex Pre-Heater Module" },
    { name: "Synchrodex Solder Module" },
    { name: "Synchrodex Pro 1060mm Fluxer Module" },
    { name: "Synchrodex Pro 1060mm Pre-Heater Module" },
    { name: "Synchrodex Pro 1060mm Solder Module" },
    { name: "Synchrodex Pro 1600mm Pre-Heater Module" },
    { name: "Synchrodex Pro 1600mm Single Bath Solder Module" },
    { name: "Synchrodex Pro 1600mm Dual Bath Solder Module" }
  ];



  const { product, cells, lineItemId } = productItem;

  const handlePopoverOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClickProduct = prod => {
    handleAddProduct(prod);
    handlePopoverClose();
  };

  return (
    <div
      className={`${styles.container} ${!!product && styles.hoveredOn} ${!!anchorEl && styles.active
        } ${selectedLineItem?.lineItemId === lineItemId && styles.isSelected} ${selectedLineItem && styles.isExpanded
        }`}
      onClick={() => handleSelectLineItem(productItem)}
    >
      {!product && <IconSettingsLight />}
      {!!product && (
        <>
          <div className={styles.cellsBlock}>
            {product.cells?.map((cell, idx) => {
              const actualCell = cells?.[idx] || cell;
              return (
                <div
                  key={idx}
                  className={styles.cellItem}
                  onClick={e => {
                    e.stopPropagation();
                    handleOpenSelectCell(lineItemId, product, cells, idx);
                  }}
                >
                  {actualCell?.image ? (
                    <CellItem cell={actualCell} size="xs" />
                  ) : (
                    <CellItem size="xs" />
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.itemProductImgBlock}>
            <img
              alt={product?.name}
              src={product.configurator?.item_image || product.img}
              className={styles.itemProductImg}
            />
          </div>
        </>
      )}
      <div className={styles.order}>{index}</div>
      <div className={styles.icons}>
        {!product && (
          <>
            <div
              onClick={handlePopoverOpen}
              className={`${styles.iconItem} ${!!anchorEl && styles.active}`}
            >
              <IconSettings color="#2E2E2E" width={20} height={20} />
            </div>
            <MainPopover
              popoverRef={popoverRef}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
            >
              <div className={styles.popoverContent}>
                <h3 className={styles.title}>{translate("Choose Machine")}</h3>
                <div className={styles.products}>
                  {items.map(prod => {
                    let isEnabled = false;
                    if (prod.name === "Fusion 4") {
                      isEnabled = true;
                      prod = productsList.find(item => item.name === "Orissa Fusion 4");
                    } else if (prod.name === "Synchrodex Pro 1600mm Dual Bath Solder Module") {
                      isEnabled = true;
                      prod = productsList.find(item => item.name === "Synchrodex Pro 1600 Dual Bath Solder Module");
                    }

                    return (
                      <p
                        onClick={() => isEnabled ? handleClickProduct(prod) : null}
                        className={`${styles.prodName} ${!isEnabled ? styles.disabled : ''}`}
                        key={prod.id}
                      >
                        {prod.name}
                      </p>
                    )
                  }
                  )}
                </div>
              </div>
            </MainPopover>
          </>
        )}
        <div onClick={handleClickDelete} className={styles.iconItem}>
          <IconDeleteRecycle />
        </div>
      </div>
    </div>
  );
};
