import styles from "./RowListItem.module.scss";
import { IconUserRoundOutline, IconLocationOutline } from "icons";

export const RowListItem = ({ image, name, address, representative }) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={image} alt={name} />
      </div>
      <div className={styles.descBlock}>
        <h6 className={styles.spaceName}>{name}</h6>
        <div className={styles.row}>
          <IconLocationOutline />
          <p className={styles.text}>{address}</p>
        </div>
        <div className={styles.row}>
          <IconUserRoundOutline />
          <p className={styles.text}>{representative}</p>
        </div>
      </div>
    </div>
  );
};
