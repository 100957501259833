import React, { forwardRef } from "react";
import {
  MenuItem,
  Select as MUISelect,
  FormControl,
  ListItemText,
  Radio,
} from "@mui/material";
import styles from "./Select.module.scss";
import { IconInfo } from "icons";
import { IconArrowDown } from "icons";
import { MainTooltip } from "../MainTooltip";

export const Select = forwardRef(
  (
    {
      options,
      value,
      onChange,
      name,
      size = "md",
      label = "Choose",
      ToolTipContentHTML,
    },
    ref
  ) => {
    return (
      <FormControl fullWidth variant="outlined" className={styles.formControl}>
        <MUISelect
          name={name}
          value={value}
          onChange={onChange}
          className={`${styles.select} ${styles[size]}`}
          displayEmpty
          sx={{
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#404040",
            },
          }}
          IconComponent={props => (
            <IconArrowDown {...props} className={styles.customIcon} />
          )}
          MenuProps={{
            classes: { paper: styles.menuPaper, list: styles.menuList },
          }}
          renderValue={
            value !== ""
              ? selected => (
                  <div className={styles.selectedValueContainer}>
                    <span className={styles.selectedValue}>
                      {options.find(option => option.value === selected)?.label}
                    </span>
                    <>
                      {ToolTipContentHTML && (
                        <MainTooltip
                          title={ToolTipContentHTML}
                          color="black"
                          padding="12px 16px"
                          fontSize="14px"
                          fontWeight="400"
                        >
                          <span className={styles.infoIcon}>
                            <IconInfo color={"#008ECC"} />
                          </span>
                        </MainTooltip>
                      )}
                    </>
                  </div>
                )
              : () => (
                  <div className={styles.placeholderContainer}>
                    <span className={styles.placeholder}>{label}</span>
                    <>
                      {ToolTipContentHTML && (
                        <MainTooltip
                          title={ToolTipContentHTML}
                          color="black"
                          padding="12px 16px"
                          fontSize="14px"
                          fontWeight="400"
                        >
                          <span className={styles.infoIcon}>
                            <IconInfo color={"#008ECC"} />
                          </span>
                        </MainTooltip>
                      )}
                    </>
                  </div>
                )
          }
          inputProps={{ "aria-label": "Without label" }}
        >
          {options.map((option, index) => (
            <MenuItem
              ref={ref}
              key={index}
              value={option.value}
              className={styles.menuItem}
            >
              <Radio
                checked={value === option.value}
                value={option.value}
                classes={{
                  root: styles.radio,
                  checked: styles.checkedRadio,
                }}
                readOnly
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </MUISelect>
      </FormControl>
    );
  }
);
