export const constructPdfExportBody = data => {
  const products = data.chosenProducts.map(prod => {
    const cells = prod.cells.map(cell => cell?.backend_name || "");
    return { ...prod.product, cells };
  });

  const res = products.map(p => (p?.id ? p : { ...p, id: "" }));

  return res;
};
