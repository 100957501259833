import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { STANDARD_FEATURES_ICONS } from "reduxStore/mockData";
import styles from "./StandardFeatureItem.module.scss";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: "12px 16px",
    fontSize: "14px",
    fontWeight: 400,
  },
}));

export const StandardFeatureItem = props => {
  const { feature, handleSelect, isActive } = props;
  const iconElement = STANDARD_FEATURES_ICONS[feature?.icon] || null;

  return (
    <CustomTooltip
      title={feature?.name || ""}
      placement="bottom"
      enterDelay={300}
      leaveDelay={200}
    >
      <div
        onClick={() => handleSelect(feature)}
        className={`${styles.container} ${isActive && styles.active}`}
      >
        <div className={styles.iconContainer}>{iconElement}</div>
        <p className={styles.featureTxt}>{feature?.name}</p>
      </div>
    </CustomTooltip>
  );
};
