import { Box, LinearProgress } from "@mui/material";
import styles from "./ProductPropertyItem.module.scss";

export const ProductPropertyItem = props => {
  const { details } = props;
  return (
    <div className={styles.container}>
      <p className={styles.name}>{details.name}</p>
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <Box sx={{ width: "150px", mr: 4 }}>
          <LinearProgress
            sx={{
              borderRadius: "10px",
              "& .MuiLinearProgress-bar": {
                borderRadius: "10px",
                backgroundColor: "#008ecc",
              },
              backgroundColor: "#9499C333",
            }}
            variant="determinate"
            value={details.progress || 0}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <p
            className={styles.measurementUnit}
            variant="body2"
            color="text.secondary"
          >
            {details.value}
          </p>
        </Box>
      </Box>
    </div>
  );
};
