import { useEffect } from "react";

export const useScrollSpy = (sections, setCurrentSection, containerElRef) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (
            entry.intersectionRatio > 0 &&
            entry.boundingClientRect.top <= 100
          ) {
            const sectionNumber = parseInt(
              entry.target.getAttribute("data-section"),
              10
            );
            if (!isNaN(sectionNumber)) {
              setCurrentSection(sectionNumber);
            }
          }
        });
      },
      {
        root: containerElRef.current,
        threshold: 1.0,
        rootMargin: "0px",
      }
    );

    sections.forEach(section => {
      const element = document.getElementById(section.id);
      if (element) {
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, [sections, setCurrentSection, containerElRef]);
};
