import styles from "./AdvantageCard.module.scss";

export const AdvantageCard = ({ icon, title, text }) => (
  <div className={styles.container}>
    <div className={styles.head}>
      <div className={styles.iconBlock}>{icon}</div>
      <h6 className={styles.title}>{title}</h6>
    </div>
    <p className={styles.text}>{text}</p>
  </div>
);
