import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// change BrowserRouter to HashRouter to build and use for local downloads as zip
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";

import { MainLayout } from "./Layouts/MainLayout";
import { MarketSector, Home, Product } from "./pages";

import "./App.scss";
import { fetchDeviceListAsync } from "reduxStore/slices/configuratorSlice";
import packageJson from "../package.json";
import { resetConfigurator } from "reduxStore/slices/configuratorSlice";
import { Enhancements } from "pages/Enhancements";
import { Technology } from "pages/Technology";
import { PasswordDialog } from "shared/components/PasswordDialog";
import { fetchCompaniesListAsync } from "reduxStore/slices/companySlice";

function App() {
  const { version } = packageJson;
  const dispatch = useDispatch();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const storedVersion = localStorage.getItem("appVersion");

    if (storedVersion !== version) {
      dispatch(resetConfigurator());
      localStorage.setItem("appVersion", version);
    }
    dispatch(fetchDeviceListAsync());
    dispatch(fetchCompaniesListAsync());
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch, version]);

  return (
    <Router>
      <PasswordDialog
        version={version}
        onVersionMismatch={() => dispatch(resetConfigurator())}
      />
      <Routes>
        <Route path="/" element={<Navigate to="/general" replace />} />
        <Route path="/:company?" element={<Home />} />
        <Route path="/" element={<MainLayout isScrolled={isScrolled} />}>
          <Route path=":company/market" element={<MarketSector />} />
          <Route path=":company/product" element={<Product />} />
          <Route path=":company/enhancements" element={<Enhancements />} />
          <Route path=":company/technology" element={<Technology />} />
        </Route>
        <Route path="*" element={<Navigate to="/general" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
