import { useTranslation } from "hooks/useTranslation";
import styles from "./InfoBlock.module.scss";

export const InfoBlock = ({ section, title, paragraphs }) => {
  const { translate } = useTranslation();

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{translate(title)}</h4>
      <div className={styles.content}>
        {paragraphs.map((paragraph, idx) => (
          <p key={idx} className={styles.paragraph}>
            {translate(paragraph)}
          </p>
        ))}
      </div>
    </div>
  );
};
