import ProductImage from "assets/images/products/productImg.png";
import DetailImg from "assets/images/products/productDetailImg.png";
import CellImageHTop from "assets/images/configurator/cells/H-top.png";
import CellImageHBottom from "assets/images/configurator/cells/H-bottom.png";
import CellImageF from "assets/images/configurator/cells/F.png";
import CellImageWS from "assets/images/configurator/cells/WS.png";
import CellImageHH from "assets/images/configurator/cells/HH.png";
import CellImageS from "assets/images/configurator/cells/S.png";
import CellImageHS from "assets/images/configurator/cells/HS.png";
import CellImageMD from "assets/images/configurator/cells/MD.png";
import CellImageHWS from "assets/images/configurator/cells/HWS.png";
import CellImageHF from "assets/images/configurator/cells/HF.png";
import CellImageHFF from "assets/images/configurator/cells/HFF.png";
import CellImageHMD from "assets/images/configurator/cells/HMD.png";
import CellImageHSS from "assets/images/configurator/cells/HSS.png";
import CellImageHHFF from "assets/images/configurator/cells/HHFF.png";
import CellImageHJW from "assets/images/configurator/cells/HJW.png";
import CellImageHHJW from "assets/images/configurator/cells/HHJW.png";
import CellImageHDS from "assets/images/configurator/cells/HDS.png";
import { IconLikeCompass, IconMonitor } from "icons";

export const STANDARD_FEATURES_ICONS = {
  iconMonitor: <IconMonitor width="32" height="32" color="#000" />,
  iconLikeCompass: <IconLikeCompass />,
};

export const EMPTY_PRODUCT_CELL = {
  id: 100,
  name: "Empty",
  with: "No Device",
  isEmpty: true,
};

export const PRODUCT_CELLS = [
  {
    id: 1,
    name: "Flux Module",
    backend_name: "F",
    with: "with spray fluxer",
    configuratorTitle: "High Precision drop jet",
    description:
      "Enhance your soldering precision and efficiency with our cutting-edge Single Flux Head Module. This high-precision drop jet head features changeable orifice sizes ranging from 100 to 300 microns, allowing you to customize your fluxing process to meet the exact requirements of your application. Our patented return system automatically purges the head with our unique auto flux purge system, supporting much higher solids capability than other industry drop jet technologies. The drop jet controls the number of droplets in a given area and droplet size, and it can also operate in draw mode, spraying lines for maximum efficiency. Integrated with our best-in-class software, you can easily drag and drop patterns to ensure high accuracy with very fast and intuitive programming. Upgrade to the Single Flux Head Module and experience unparalleled precision, flexibility, and ease of use in your soldering processes.",
    image: CellImageF,
  },
  {
    id: 2,
    name: "Flux Module",
    with: "with spray fluxer and preheating",
    backend_name: "HTF",
    find_name: "HF",
    configuratorTitle: "High Precision drop je w/ Top IR",
    description:
      "Discover the next generation of selective soldering with our advanced Single Flux Head Module, combined with state-of-the-art Topside and Bottomside IR Preheaters. The Single Flux Head Module offers high-precision drop jet capabilities with changeable orifice sizes from 100 to 300 microns and a patented return system for automatic purging, supporting higher solids capability. This module allows the use of a single chemistry for high-speed operations and controls droplet number, size, and can operate in draw mode for efficiency. Integrated with our best-in-class software, patterns can be easily dragged and dropped for high accuracy and intuitive programming. Complementing this is our IR preheaters, featuring multi-bank individually controlled lamps with closed-loop control, ensuring consistent heating regardless of incoming PCB temperature, while maintaining optimal temperature throughout the process. Together, these technologies provide the perfect blend of speed, precision, and control for all your selective soldering needs. Upgrade today and experience unparalleled efficiency and flexibility.",
    image: CellImageHF,
  },
  {
    id: 3,
    name: "Flux Module",
    with: "with dual pots spray fluxer and preheating",
    backend_name: "HFF",
    configuratorTitle: "Dual High Precision drop jet w/ Top  IR",
    description:
      "Discover the next generation of selective soldering with our advanced dual Single Flux Head Modules, combined with our state-of-the-art Topside IR Preheaters. The dual Single Flux Head Modules offer high-precision drop jet capabilities with changeable orifice sizes from 100 to 300 microns and a patented return system for automatic purging, supporting higher solids capability. These modules allow the use of two different flux chemistries with self-contained pressurized systems or a single chemistry for high-speed operations, controlling droplet number, size, and operating in draw mode for efficiency. Integrated with our best-in-class software, patterns can be easily dragged and dropped for high accuracy and intuitive programming. Complementing this is our Topside IR Preheater, featuring multi-bank individually controlled lamps with closed-loop control, ensuring consistent heating regardless of incoming PCB temperature, and maintaining optimal temperature throughout the process. Together, these technologies provide the perfect blend of speed, precision, and control for all your selective soldering needs. Upgrade today and experience unparalleled efficiency and flexibility.",
    image: CellImageHFF,
  },
  {
    id: 4,
    name: "Flux Module",
    backend_name: "HHFF",
    with: "",
    configuratorTitle: "Dual flux heads w/ Top/Bottom IR",
    description:
      "Discover the next generation of selective soldering with our state-of-the-art Topside and Bottomside IR Preheaters, combined with dual Single Flux Head Modules. Our IR preheaters feature multi-bank individually controlled lamps with closed-loop control, ensuring consistent heating regardless of incoming PCB temperature, while maintaining optimal temperature throughout the process. The dual Single Flux Head Modules offer high-precision drop jet capabilities with changeable orifice sizes from 100 to 300 microns and a patented return system for automatic purging, supporting higher solids capability. These modules allow the use of two different flux chemistries with self-contained pressurized systems or a single chemistry for high-speed operations. The drop jets control droplet number, size, and can operate in draw mode for efficiency. Integrated with our best-in-class software, patterns can be easily dragged and dropped for high accuracy and intuitive programming. Together, these technologies provide the perfect blend of speed, precision, and control for all your selective soldering needs.",
    image: CellImageHHFF,
  },
  {
    id: 5,
    name: "Preheat Module",
    with: "with top side preheating",
    backend_name: "HT",
    configuratorTitle: "Top IR Closed Loop",
    description:
      "Discover the next generation of selective soldering with our state-of-the-art Topside IR Preheater. Designed for precision and efficiency, this module features multi-bank individually controlled lamps that can be turned on and off as needed, with closed-loop control ensuring consistent heating regardless of incoming PCB temperature. Enjoy rapid response times and automatic ramp rate control for smooth and precise temperature changes. Integrated profile generation allows unique heating profiles to be attached to each PCB's serial number, embracing the power of Industry 4.0. Additionally, this preheater keeps the PCB at the optimal temperature throughout the process, ensuring reliable and consistent results. Efficient and gentle, our preheater is ideal for most selective soldering applications, protecting your components while achieving perfect solder joints every time. Upgrade today and experience the perfect blend of speed, precision, and control.",
    image: CellImageHTop,
  },
  {
    id: 6,
    name: "Preheat Module",
    with: "with bottom preheating",
    configuratorTitle: "Bottom IR Closed Loop",
    backend_name: "HB",
    description:
      "Discover the next generation of selective soldering with our state-of-the-art Bottomside IR Fast Acting Heater Module. Designed for precision and efficiency, this module features multi-bank individually controlled lamps that can be turned on and off as needed, with closed-loop control ensuring consistent heating regardless of incoming PCB temperature. Enjoy rapid response times and automatic ramp rate control for smooth and precise temperature changes. Integrated profile generation allows unique heating profiles to be attached to each PCB's serial number, embracing the power of Industry 4.0. Efficient and gentle, our heater module is ideal for most selective soldering applications, protecting your components while achieving perfect solder joints every time. Upgrade today and experience the perfect blend of speed, precision, and control.",
    image: CellImageHBottom,
  },
  {
    id: 7,
    name: "Preheat Module",
    with: "with top and bottom preheating",
    backend_name: "HH",
    configuratorTitle: "Top/Bottom IR Closed Loop",
    description:
      "Discover the next generation of selective soldering with our state-of-the-art Topside and Bottom side IR Preheaters. Designed for precision and efficiency, these modules feature multi-bank individually controlled lamps that can be turned on and off as needed, with closed-loop control ensuring consistent heating regardless of incoming PCB temperature. Enjoy rapid response times and automatic ramp rate control for smooth and precise temperature changes. Integrated profile generation allows unique heating profiles to be attached to each PCB's serial number, embracing the power of Industry 4.0. Additionally, these preheaters keep the PCB at the optimal temperature throughout the process, ensuring reliable and consistent results. Efficient and gentle, our IR preheaters are ideal for most selective soldering applications, protecting your components while achieving perfect solder joints every time. Upgrade today and experience the perfect blend of speed, precision, and control.",
    image: CellImageHH,
  },
  {
    id: 8,
    name: "Solder Module",
    with: "with single pot",
    configuratorTitle: "Single Solder bath",
    backend_name: "S",
    image: CellImageS,
    description:
      "Introducing the Single Solder Module with complete XYZ axis programming control, designed to seamlessly integrate with all Pillarhouse soldering technologies. This versatile module is compatible with 1.5mm micro thermal nozzles, AP-1, Jet-Tip, Jetwave, and more. With quick nozzle change capability, thermal nozzle calibration, and optional nozzle detection, Pillarhouse offers unmatched keepout zone capability. Experience unique and powerful Jetwave soldering methods for connector and high thermal mass processes. The system is not only fast but also completely flexible, ensuring precision and efficiency in all your soldering applications. Upgrade to the Single Solder Module today and take your soldering processes to the next level with Pillarhouse's cutting-edge technology.All baths are available with either mechanical or magnetic drive options, ensuring optimal performance and reliability.All baths are available with either mechanical or magnetic drive options, ensuring optimal performance and reliability",
  },
  {
    id: 9,
    name: "Solder Module",
    with: "with single pot and preheating",
    backend_name: "HTS",
    find_name: "HS",
    configuratorTitle: "Single Solder bath w/ Top IR",
    description:
      "Introducing the Single Solder Module with complete XYZ axis programming control, designed to seamlessly integrate with all Pillarhouse soldering technologies and our state-of-the-art Topside IR Preheat. This versatile module is compatible with 1.5mm micro thermal nozzles, AP-1, Jet-Tip, Jetwave, and more. With quick nozzle change capability, thermal nozzle calibration, and optional nozzle detection, Pillarhouse offers unmatched keepout zone capability. Experience unique and powerful Jetwave soldering methods for connector and high thermal mass processes. The integrated Topside IR Preheat ensures consistent and optimal temperature control throughout the soldering process. The system is not only fast but also completely flexible, ensuring precision and efficiency in all your soldering applications. Upgrade to the Single Solder Module today and take your soldering processes to the next level with Pillarhouse's cutting-edge technology.All baths are available with either mechanical or magnetic drive options, ensuring optimal performance and reliability",
    image: CellImageHS,
  },
  {
    id: 10,
    name: "Solder Module",
    with: "with dual independant pots and preheating",
    backend_name: "HTSS",
    configuratorTitle: "Dual Independent solder w/ Top IR",
    description:
      "Introducing the Dual Independent XYZ Solder Bath Assemblies with Topside Preheat, engineered for maximum flexibility and efficiency. Each solder bath operates independently with its own XYZ axis, enabling completely parallel operation for unmatched productivity. The integrated Topside Preheat ensures consistent temperature control, enhancing the precision of every soldering process. These independent assemblies provide the ultimate in flexibility, allowing you to handle diverse processes simultaneously with optimal efficiency. Like all Pillarhouse systems, they offer exceptional control, including warpage correction and thermal nozzle calibration, for precise and repeatable results.",
    image: CellImageHSS,
  },
  {
    id: 11,
    name: "Solder Module",
    backend_name: "HTDSS",
    with: "with dual pots and preheating",
    configuratorTitle: "Dual Solder on single XY w/ auto adjust",
    description:
      "Introducing the Dual Solder Pots with Auto Width Adjust, designed for unmatched flexibility and speed. The Pillarhouse Dual Bath System incorporates a single XY axis with automatic width adjustment between the solder pots and independent Z control. This innovative system allows the solder baths to be utilized for different processes or ganged with a single checkbox for high-speed performance. Like all Pillarhouse processes, it offers the utmost control, including warpage correction and thermal nozzle calibration, delivering more precise and repeatable results. All baths are available with either mechanical or magnetic drive options, ensuring optimal performance and reliability. Upgrade to the Pillarhouse Dual Solder Pots system today for superior efficiency and precision in your soldering applications.",
    image: CellImageHDS,
  },
  {
    id: 12,
    name: "Solder Module",
    backend_name: "WS",
    with: "with multi wave module",
    configuratorTitle: "Single Wide-wave",
    description:
      "Introducing the Single Solder Module with complete XYZ axis programming control, now featuring Wide Wave Single Pot Soldering. This advanced system accomplishes in a flexible and efficient way what small wave soldering machines are designed to do. The Wide Wave operates in a fully inerted atmosphere to enhance capillary action, making it ideal for customers transitioning from full wave processes to higher mixed technology PCBs. With quick nozzle change capability, thermal nozzle calibration, and optional nozzle detection, Pillarhouse offers unmatched keepout zone capability. The integrated Topside IR Preheat ensures consistent and optimal temperature control throughout the soldering process. Fast, flexible, and precise, the Single Solder Module with Wide Wave technology is the perfect solution for all your soldering needs. Upgrade today and experience the next level of soldering efficiency and accuracy with Pillarhouse's cutting-edge technology.",
    image: CellImageWS,
  },
  {
    id: 13,
    name: "Solder Module",
    with: "",
    backend_name: "HTWS",
    configuratorTitle: "Single Wide-wave w/ topside",
    description:
      "Introducing the Single Solder Module with complete XYZ axis programming control, now featuring Wide Wave Single Pot Soldering and integrated Topside IR Preheat. This advanced system accomplishes in a flexible and efficient way what small wave soldering machines are designed to do. The Wide Wave operates in a fully inerted atmosphere to enhance capillary action, making it ideal for customers transitioning from full wave processes to higher mixed technology PCBs. With quick nozzle change capability, thermal nozzle calibration, and optional nozzle detection, Pillarhouse offers unmatched keepout zone capability. The integrated Topside IR Preheat ensures consistent and optimal temperature control throughout the soldering process. Fast, flexible, and precise, the Single Solder Module with Wide Wave technology and Topside IR Preheat is the perfect solution for all your soldering needs. Upgrade today and experience the next level of soldering efficiency and accuracy with Pillarhouse's cutting-edge technology.",
    image: CellImageHWS,
  },
  {
    id: 14,
    name: "Solder Module",
    with: "with multi wave module",
    backend_name: "MD",
    configuratorTitle: "Single Multiwave",
    description:
      "Introducing the Single Solder Module with XYZ axis programming control, featuring the Multiwave Module for unmatched speed and efficiency. Designed with custom nozzles for straight dipping, it’s perfect for OEM environments with limited product changeover and high throughput demands. Operating in a fully inerted atmosphere, it enhances capillary action and smoothly transitions from full wave processes to higher mixed technology PCBs. Ideal for multi-module platforms, it combines flexible cells for optimal speed and efficiency. With quick nozzle change capability, thermal nozzle calibration, optional nozzle detection, and integrated Topside IR Preheat for consistent temperature control, this fast, flexible, and precise system is your ultimate soldering solution. Consult Pillarhouse on applicable products and upgrade today to experience cutting-edge soldering technology.",
    image: CellImageMD,
  },
  {
    id: 15,
    name: "Solder Module",
    with: "with multi wave module and preheating",
    backend_name: "HTMD",
    configuratorTitle: "Solder/Topside IR/ w/multiwave",
    description:
      "Introducing the Single Solder Module with XYZ axis programming control, featuring the Multiwave Module and integrated Topside IR Preheat for unmatched speed and efficiency. Designed with custom nozzles for straight dipping, it’s perfect for OEM environments with limited product changeover and high throughput demands. Operating in a fully inerted atmosphere, it enhances capillary action and smoothly transitions from full wave processes to higher mixed technology PCBs. Ideal for multi-module platforms, it combines flexible cells for optimal speed and efficiency. With quick nozzle change capability, thermal nozzle calibration, optional nozzle detection, and consistent temperature control from the Topside IR Preheat, this fast, flexible, and precise system is your ultimate soldering solution. Consult Pillarhouse on applicable products and upgrade today to experience cutting-edge soldering technology.",
    image: CellImageHMD,
  },
  {
    id: 16,
    name: "Solder Module",
    backend_name: "HTJW",
    with: null,
    configuratorTitle: "Jetwave w/ Top IR",
    description:
      "Introducing Jetwave Soldering Technology with integrated Topside IR Preheat, designed to meet the rigorous demands of high-specification military and aerospace connectors. Engineered to deliver full topside fillets on multilayer, high thermal mass PCBs, Jetwave offers high solder projection and unmatched relative flow rate, accommodating long leads and ensuring a process free of bridging. Available in contact widths ranging from 12 to 60mm, Jetwave is ideal for high throughput situations. Requiring little to no maintenance apart from routine dross removal, Jetwave ensures consistent and reliable performance. The integrated Topside IR Preheat ensures optimal temperature control throughout the soldering process, enhancing precision and efficiency. Upgrade to Jetwave Soldering Technology today for superior soldering performance and reliability in even the most demanding applications.",
    image: CellImageHJW,
  },
  {
    id: 17,
    name: "Solder Module",
    backend_name: "HHJW",
    with: null,
    configuratorTitle: "Jetwave w/ Top/Bottom IR",
    description:
      "Introducing Jetwave Soldering Technology with integrated Topside and Bottomside IR Preheat, designed to meet the rigorous demands of high-specification military and aerospace connectors. Engineered to deliver full topside fillets on multilayer, high thermal mass PCBs, Jetwave offers high solder projection and unmatched relative flow rate, accommodating long leads and ensuring a process free of bridging. Available in diameters ranging from 12 to 60mm, Jetwave is ideal for high throughput situations. Requiring little to no maintenance apart from routine dross removal, Jetwave ensures consistent and reliable performance. The integrated Topside and Bottomside IR Preheat ensures optimal temperature control throughout the soldering process, enhancing precision and efficiency.",
    image: CellImageHHJW,
  },
];

export const PRODUCTS = [
  {
    name: "Synchrodex Solder",
    id: 1,
    img: ProductImage,
    detailImg: DetailImg,
    cells: [{ id: 1, img: null, name: null }],
    options: [
      {
        id: 1,
        name: "Units Per Hour",
        measurementUnit: "7.68 u/h",
        progress: 80,
      },
      {
        id: 2,
        name: "Fluxes Per Hour",
        measurementUnit: "12.7 f/m",
        progress: 60,
      },
      { id: 3, name: "Inclination", measurementUnit: "58.67°", progress: 65 },
      { id: 4, name: "Start Time", measurementUnit: "1m 04s", progress: 10 },
      {
        id: 5,
        name: "Defects Per 100 Unit",
        measurementUnit: "2/100 d",
        progress: 10,
      },
      { id: 6, name: "Energy Class", measurementUnit: "A++", progress: 100 },
    ],
  },
  {
    name: "Synchrodex Single Solder",
    id: 2,
    img: ProductImage,
    detailImg: DetailImg,
    cells: [{ id: 1, img: null, name: null }],
    options: [
      {
        id: 1,
        name: "Units Per Hour",
        measurementUnit: "7.68 u/h",
        progress: 80,
      },
      {
        id: 2,
        name: "Fluxes Per Hour",
        measurementUnit: "12.7 f/m",
        progress: 60,
      },
      { id: 3, name: "Inclination", measurementUnit: "58.67°", progress: 65 },
      { id: 4, name: "Start Time", measurementUnit: "1m 04s", progress: 10 },
      {
        id: 5,
        name: "Defects Per 100 Unit",
        measurementUnit: "2/100 d",
        progress: 10,
      },
      { id: 6, name: "Energy Class", measurementUnit: "A++", progress: 100 },
    ],
  },
  {
    name: "Synchrodex Twin Solder",
    id: 3,
    img: ProductImage,
    detailImg: DetailImg,
    cells: [{ id: 1, img: null, name: null }],
    options: [
      {
        id: 1,
        name: "Units Per Hour",
        measurementUnit: "7.68 u/h",
        progress: 80,
      },
      {
        id: 2,
        name: "Fluxes Per Hour",
        measurementUnit: "12.7 f/m",
        progress: 60,
      },
      { id: 3, name: "Inclination", measurementUnit: "58.67°", progress: 65 },
      { id: 4, name: "Start Time", measurementUnit: "1m 04s", progress: 10 },
      {
        id: 5,
        name: "Defects Per 100 Unit",
        measurementUnit: "2/100 d",
        progress: 10,
      },
      { id: 6, name: "Energy Class", measurementUnit: "A++", progress: 100 },
    ],
  },
  {
    name: "Fusion 3",
    id: 4,
    img: ProductImage,
    detailImg: DetailImg,
    cells: [
      { id: 1, img: null, name: null },
      { id: 2, img: null, name: null },
      { id: 3, img: null, name: null },
    ],
    options: [
      {
        id: 1,
        name: "Units Per Hour",
        measurementUnit: "7.68 u/h",
        progress: 80,
      },
      {
        id: 2,
        name: "Fluxes Per Hour",
        measurementUnit: "12.7 f/m",
        progress: 60,
      },
      { id: 3, name: "Inclination", measurementUnit: "58.67°", progress: 65 },
      { id: 4, name: "Start Time", measurementUnit: "1m 04s", progress: 10 },
      {
        id: 5,
        name: "Defects Per 100 Unit",
        measurementUnit: "2/100 d",
        progress: 10,
      },
      { id: 6, name: "Energy Class", measurementUnit: "A++", progress: 100 },
    ],
  },
  {
    name: "Fusion 4",
    id: 5,
    img: ProductImage,
    detailImg: DetailImg,
    cells: [
      { id: 1, img: null, name: null },
      { id: 2, img: null, name: null },
      { id: 3, img: null, name: null },
      { id: 3, img: null, name: null },
    ],
    options: [
      {
        id: 1,
        name: "Units Per Hour",
        measurementUnit: "7.68 u/h",
        progress: 80,
      },
      {
        id: 2,
        name: "Fluxes Per Hour",
        measurementUnit: "12.7 f/m",
        progress: 60,
      },
      { id: 3, name: "Inclination", measurementUnit: "58.67°", progress: 65 },
      { id: 4, name: "Start Time", measurementUnit: "1m 04s", progress: 10 },
      {
        id: 5,
        name: "Defects Per 100 Unit",
        measurementUnit: "2/100 d",
        progress: 10,
      },
      { id: 6, name: "Energy Class", measurementUnit: "A++", progress: 100 },
    ],
  },
];

export const PRODUCT_LIST_FROM_BACK = [
  {
    device_id: 5,
    device_name: "Fusion 4",
    cellnum: 4,
    details:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    standard_features: [
      {
        name: "Initial Solder Fill 63:37 only",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Accessory Kit",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Lead-Free Capability",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Multilevel Password Security System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Live data visibility",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Live monitoring with starved system tracking & downtime log",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Per cycle complete program audit record for traceability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Intelligent comveyor senses and controls speed before stop for board protection",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Automatic board side clamping-programmable standard",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Internal temperature monitoring for homogeneous process",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Machine locked feature-electromagnetic locks-default safe even tools!",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarPAD Offline Programming Package",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Universally Adjustable Tooling Carriers",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "PillarCOMM.NET Windows Software",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Internal Fume Extraction",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Integral Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
    ],
    optional_features: [
      {
        name: "Jet Wave or Custom Nozzles",
        icon: "iconHeater",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Barcode Reader",
        icon: "iconBarcodeReader",
        configured: true,
        description:
          "Allows automatic process program selection and board traceability, both locally and remotely.",
        link: "Enhancements -> Other -> Barcodes and Board Traceability",
      },
      {
        name: "Nitrogen Mass Flow Meter",
        icon: "iconFlowMeter",
        configured: true,
        description: "Measures oxygen level in PPM at the solder nozzle.",
        link: "Enhancements -> Soldering -> Nitrogen Purity Analyser",
      },
      {
        name: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Additional Flux Head",
        icon: "iconFluxHead",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Bottom Side Pre-Heat",
        icon: "iconBottomPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Top Side Pre-Heat",
        icon: "iconTopPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Electromagnetic Pump Assembly",
        icon: "iconElectromagneticPump",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Fiducial Correction",
        icon: "iconFiducialCorrection",
        configured: false,
        description: "Corrects board positional errors in X, Y and theta.",
        link: "Enhancements -> Other -> Fiducial Correction",
      },
    ],
    other_options: [
      {
        name: "Ring Heater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Dual Bath",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Optical Flux Presence Sensor",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Flux Flow Monitoring",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "MES (Manufacturing Equipment Systems)",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Extraction Anamometer",
        configured: false,
        description: null,
        link: null,
      },
    ],
  },
  {
    device_id: 4,
    device_name: "Fusion 3",
    cellnum: 3,
    details:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    standard_features: [
      {
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "PillarCOMM.NET Windows Software",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Internal Fume Extraction",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Integral Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Live data visibility",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Live monitoring with starved system tracking & downtime log",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Per cycle complete program audit record for traceability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Intelligent comveyor senses and controls speed before stop for board protection",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Automatic board side clamping-programmable standard",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Internal temperature monitoring for homogeneous process",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Machine locked feature-electromagnetic locks-default safe even tools!",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Initial Solder Fill 63:37 only",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarPAD Offline Programming Package",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Universally Adjustable Tooling Carriers",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Accessory Kit",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Lead-Free Capability",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Multilevel Password Security System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
    ],
    optional_features: [
      {
        name: "Jet Wave or Custom Nozzles",
        icon: "iconHeater",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Barcode Reader",
        icon: "iconBarcodeReader",
        configured: true,
        description:
          "Allows automatic process program selection and board traceability, both locally and remotely.",
        link: "Enhancements -> Other -> Barcodes and Board Traceability",
      },
      {
        name: "Nitrogen Mass Flow Meter",
        icon: "iconFlowMeter",
        configured: true,
        description: "Measures oxygen level in PPM at the solder nozzle.",
        link: "Enhancements -> Soldering -> Nitrogen Purity Analyser",
      },
      {
        name: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Additional Flux Head",
        icon: "iconFluxHead",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Bottom Side Pre-Heat",
        icon: "iconBottomPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Top Side Pre-Heat",
        icon: "iconTopPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Electromagnetic Pump Assembly",
        icon: "iconElectromagneticPump",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Fiducial Correction",
        icon: "iconFiducialCorrection",
        configured: false,
        description: "Corrects board positional errors in X, Y and theta.",
        link: "Enhancements -> Other -> Fiducial Correction",
      },
    ],
    other_options: [
      {
        name: "Ring Heater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Dual Bath",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Optical Flux Presence Sensor",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Flux Flow Monitoring",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "MES (Manufacturing Equipment Systems)",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Extraction Anamometer",
        configured: false,
        description: null,
        link: null,
      },
    ],
  },
  {
    device_id: 3,
    device_name: "Synchrodex Twin Solder",
    cellnum: 2,
    details:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    standard_features: [
      {
        name: "Accessory Kit",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Multilevel Password Security System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Internal Fume Extraction",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Integral Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Live data visibility",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Live monitoring with starved system tracking & downtime log",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Per cycle complete program audit record for traceability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Intelligent comveyor senses and controls speed before stop for board protection",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Automatic board side clamping-programmable standard",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Internal temperature monitoring for homogeneous process",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Machine locked feature-electromagnetic locks-default safe even tools!",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Initial Solder Fill 63:37 only",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Lead-Free Capability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarCOMM.NET Windows Software",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Universally Adjustable Tooling Carriers",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarPAD Offline Programming Package",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
    ],
    optional_features: [
      {
        name: "Barcode Reader",
        icon: "iconBarcodeReader",
        configured: true,
        description:
          "Allows automatic process program selection and board traceability, both locally and remotely.",
        link: "Enhancements -> Other -> Barcodes and Board Traceability",
      },
      {
        name: "Fiducial Correction",
        icon: "iconFiducialCorrection",
        configured: true,
        description: "Corrects board positional errors in X, Y and theta.",
        link: "Enhancements -> Other -> Fiducial Correction",
      },
      {
        name: "Additional Flux Head",
        icon: "iconFluxHead",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Bottom Side Pre-Heat",
        icon: "iconBottomPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Top Side Pre-Heat",
        icon: "iconTopPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Jet Wave or Custom Nozzles",
        icon: "iconHeater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Electromagnetic Pump Assembly",
        icon: "iconElectromagneticPump",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Nitrogen Mass Flow Meter",
        icon: "iconFlowMeter",
        configured: false,
        description: "Measures oxygen level in PPM at the solder nozzle.",
        link: "Enhancements -> Soldering -> Nitrogen Purity Analyser",
      },
      {
        name: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        configured: false,
        description: null,
        link: null,
      },
    ],
    other_options: [
      {
        name: "Ring Heater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Dual Bath",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Optical Flux Presence Sensor",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Flux Flow Monitoring",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "MES (Manufacturing Equipment Systems)",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Extraction Anamometer",
        configured: false,
        description: null,
        link: null,
      },
    ],
  },
  {
    device_id: 2,
    device_name: "Synchrodex Single Solder",
    cellnum: 1,
    details:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    standard_features: [
      {
        name: "Live data visibility",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Live monitoring with starved system tracking & downtime log",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Per cycle complete program audit record for traceability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Intelligent comveyor senses and controls speed before stop for board protection",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Automatic board side clamping-programmable standard",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Internal temperature monitoring for homogeneous process",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Machine locked feature-electromagnetic locks-default safe even tools!",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Initial Solder Fill 63:37 only",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Accessory Kit",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Lead-Free Capability",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Multilevel Password Security System",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarCOMM.NET Windows Software",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "PillarPAD Offline Programming Package",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Universally Adjustable Tooling Carriers",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Internal Fume Extraction",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Integral Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
    ],
    optional_features: [
      {
        name: "Jet Wave or Custom Nozzles",
        icon: "iconHeater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Electromagnetic Pump Assembly",
        icon: "iconElectromagneticPump",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Barcode Reader",
        icon: "iconBarcodeReader",
        configured: false,
        description:
          "Allows automatic process program selection and board traceability, both locally and remotely.",
        link: "Enhancements -> Other -> Barcodes and Board Traceability",
      },
      {
        name: "Nitrogen Mass Flow Meter",
        icon: "iconFlowMeter",
        configured: false,
        description: "Measures oxygen level in PPM at the solder nozzle.",
        link: "Enhancements -> Soldering -> Nitrogen Purity Analyser",
      },
      {
        name: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Fiducial Correction",
        icon: "iconFiducialCorrection",
        configured: false,
        description: "Corrects board positional errors in X, Y and theta.",
        link: "Enhancements -> Other -> Fiducial Correction",
      },
      {
        name: "Additional Flux Head",
        icon: "iconFluxHead",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Bottom Side Pre-Heat",
        icon: "iconBottomPreHeat",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Top Side Pre-Heat",
        icon: "iconTopPreHeat",
        configured: false,
        description: null,
        link: null,
      },
    ],
    other_options: [
      {
        name: "Ring Heater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Dual Bath",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Optical Flux Presence Sensor",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Flux Flow Monitoring",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "MES (Manufacturing Equipment Systems)",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Extraction Anamometer",
        configured: false,
        description: null,
        link: null,
      },
    ],
  },
  {
    device_id: 1,
    device_name: "Synchrodex Solder",
    cellnum: 1,
    details:
      "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    units_per_hour: "0.00",
    start_time: 0,
    fluxes_per_min: "0.00",
    defects_per_unit: "0.00",
    inclination: "0.00",
    energy_class: "0.00",
    standard_features: [
      {
        name: "Live data visibility",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Live monitoring with starved system tracking & downtime log",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Per cycle complete program audit record for traceability",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Intelligent comveyor senses and controls speed before stop for board protection",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Automatic board side clamping-programmable standard",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Internal temperature monitoring for homogeneous process",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Machine locked feature-electromagnetic locks-default safe even tools!",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Initial Solder Fill 63:37 only",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Accessory Kit",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Lead-Free Capability",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Multilevel Password Security System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Process Viewing Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Solder Wave Height Measurement",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "PillarCOMM.NET Windows Software",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "PillarPAD Offline Programming Package",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Fiducial Correction System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Colour Programming Camera",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Internal Fume Extraction",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Titanium Drop Jet Fluxer - 200 Micron",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Inerted Nitrogen System",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Integral Machine Mounted Monitor and PC",
        icon: "iconLikeCompass",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Universally Adjustable Tooling Carriers",
        icon: "iconLikeCompass",
        configured: false,
        description: null,
        link: null,
      },
    ],
    optional_features: [
      {
        name: "Jet Wave or Custom Nozzles",
        icon: "iconHeater",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Electromagnetic Pump Assembly",
        icon: "iconElectromagneticPump",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Barcode Reader",
        icon: "iconBarcodeReader",
        configured: true,
        description:
          "Allows automatic process program selection and board traceability, both locally and remotely.",
        link: "Enhancements -> Other -> Barcodes and Board Traceability",
      },
      {
        name: "Nitrogen Mass Flow Meter",
        icon: "iconFlowMeter",
        configured: true,
        description: "Measures oxygen level in PPM at the solder nozzle.",
        link: "Enhancements -> Soldering -> Nitrogen Purity Analyser",
      },
      {
        name: "PCB Warpage Correction",
        icon: "iconBoardMaquette",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Fiducial Correction",
        icon: "iconFiducialCorrection",
        configured: true,
        description: "Corrects board positional errors in X, Y and theta.",
        link: "Enhancements -> Other -> Fiducial Correction",
      },
      {
        name: "Additional Flux Head",
        icon: "iconFluxHead",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Bottom Side Pyro Control",
        icon: "iconTypeCnArrowLoopReverse",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Bottom Side Pre-Heat",
        icon: "iconBottomPreHeat",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Closed-Loop Top Side Pyro Control",
        icon: "iconTypeCnArrowLoop",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Top Side Pre-Heat",
        icon: "iconTopPreHeat",
        configured: true,
        description: null,
        link: null,
      },
    ],
    other_options: [
      {
        name: "Ring Heater",
        configured: false,
        description: null,
        link: null,
      },
      {
        name: "Dual Bath",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Optical Flux Presence Sensor",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Flux Flow Monitoring",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "MES (Manufacturing Equipment Systems)",
        configured: true,
        description: null,
        link: null,
      },
      {
        name: "Extraction Anamometer",
        configured: false,
        description: null,
        link: null,
      },
    ],
  },
];
